<template>
  <v-container class="ticket-update-container">
    <CategoryTitle :category="category" />

    <!-- ############################################# -->
    <!-- ################ STEP 1 ##################### -->
    <!-- ############################################# -->

    <div class="grey lighten-3 pa-3 rounded">
      <v-form ref="form1" :disabled="disableStep1" v-model="valid">
        <h3>
          <span class="step">1</span> {{ $t("ticketUpdate.step1.header") }}
        </h3>
        <v-row no-gutters class="mt-3">
          <!-- TIGROS CARD -->
          <v-col cols="12" sm="6" md="3" class="px-1">
            <label v-html="`${$t('ticketUpdate.step1.tigrosCard')} *`" />
            <v-text-field
              outlined
              dense
              required
              :rules="fidelityCardRules"
              :placeholder="$t('ticketUpdate.step1.tigrosCardPlaceholder')"
              v-model="form1.fidelityCard"
            />
          </v-col>

          <!-- CODICE FISCALE -->
          <v-col cols="12" sm="6" md="3" class="px-1">
            <label v-html="`${$t('ticketUpdate.step1.fiscalCode')} *`" />
            <v-text-field
              outlined
              dense
              required
              :rules="cfRules"
              :placeholder="$t('ticketUpdate.step1.fiscalCodePlaceholder')"
              v-model="form1.fiscalCode"
            />
          </v-col>

          <!-- NOME -->
          <v-col cols="12" sm="6" md="3" class="px-1">
            <label v-html="`${$t('ticketUpdate.step1.firstName')} *`" />
            <v-text-field
              outlined
              dense
              required
              :rules="[requiredRules()]"
              :placeholder="$t('ticketUpdate.step1.firstNamePlaceholder')"
              v-model="form1.firstName"
            />
          </v-col>

          <!-- COGNOME -->
          <v-col cols="12" sm="6" md="3" class="px-1">
            <label v-html="`${$t('ticketUpdate.step1.lastName')} *`" />
            <v-text-field
              outlined
              dense
              required
              :rules="[requiredRules()]"
              :placeholder="$t('ticketUpdate.step1.lastNamePlaceholder')"
              v-model="form1.lastName"
            />
          </v-col>
        </v-row>
        <v-row no-gutters justify="end" v-if="step === 1" class="px-3">
          <v-btn
            :disabled="!valid"
            color="primary"
            v-html="$t('ticketUpdate.step1.button')"
            @click="findUser"
          />
        </v-row>
      </v-form>
    </div>
    <ResponseMessage
      v-if="responseFind"
      :response="responseFind"
      class="mt-1"
    />

    <!-- ############################################# -->
    <!-- ################ STEP 2 ##################### -->
    <!-- ############################################# -->

    <div class="grey lighten-3 pa-3 rounded mt-6" v-if="step >= 2">
      <v-form ref="form2" v-model="valid">
        <h3>
          <span class="step">2</span> {{ $t("ticketUpdate.step2.header") }}
        </h3>
        <div v-if="step === 2" class="mt-3">
          <h2 class="px-0 px-sm-3">
            {{ $t("ticketUpdate.step2.personalData") }}
          </h2>
          <v-row no-gutters align="center">
            <!-- STATO DI NASCITA -->
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.bornCountry')} *`" />
              <v-combobox
                :placeholder="`${$t('ticketUpdate.step2.bornCountry')} *`"
                v-model="supportData.bornCountry.name"
                :items="countryList"
                required
                height="40"
                :rules="requiredCountry"
                outlined
                dense
                return-object
                append-icon=""
                item-text="name"
                item-value="name"
                @change="selectBornCountry"
                disabled
              ></v-combobox>
            </v-col>

            <!-- LUOGO DI NASCITA -->
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.birthPlace')} *`" />
              <v-text-field
                :placeholder="
                  `${$t('ticketUpdate.step2.birthPlace')} ${
                    supportData.bornCountry.iso === 'IT' ? '*' : ''
                  }`
                "
                v-model="userData.person.birthPlace"
                required
                :rules="[requiredRules()]"
                outlined
                dense
                disabled
              />
            </v-col>

            <!-- GENERE -->
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-1 d-flex flex-column flex-sm-row align-start align-sm-center"
            >
              <label
                v-html="`${$t('ticketUpdate.step2.gender')} *`"
                class="mr-0 mr-sm-4"
              />
              <v-radio-group
                dense
                v-model="userData.person.gender"
                mandatory
                :column="!$vuetify.breakpoint.xs"
                row
                :rules="[requiredRules()]"
                disabled
              >
                <v-radio value="M"
                  ><template v-slot:label>
                    <span>M</span>
                  </template></v-radio
                >
                <v-radio value="F"
                  ><template v-slot:label>
                    <span>F</span>
                  </template></v-radio
                ></v-radio-group
              >
            </v-col>

            <!-- DATA DI NASCITA -->
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.birthDate')} *`" />
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthDate"
                    :placeholder="`${$t('ticketUpdate.step2.birthDate')} *`"
                    :rules="[requiredRules()]"
                    required
                    readonly
                    outlined
                    dense
                    disabled
                  >
                    <v-icon v-bind="attrs" v-on="on" slot="append"
                      >$editcalendar</v-icon
                    >
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="it-IT"
                  ref="picker"
                  v-model="userData.person.birthDate"
                  min="1910-01-01"
                  first-day-of-week="1"
                  type="date"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <h2 class="px-0 px-sm-3">{{ $t("ticketUpdate.step2.address") }}</h2>
          <UserAddress
            ref="addressDataForm"
            :userData.sync="userData"
            :supportData.sync="supportData"
            :isProfileForm="true"
            :ticketUpdateMode="true"
            :showButtons="false"
            @validate="validateForm2"
          />

          <h2 class="px-0 px-sm-3">{{ $t("ticketUpdate.step2.contacts") }}</h2>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.email')} *`" />
              <v-text-field
                v-model="userData.email"
                :placeholder="`${$t('ticketUpdate.step2.email')} *`"
                :rules="emailRules"
                required
                outlined
                dense
                @input="validateForm2"
              ></v-text-field>
            </v-col>
            <!-- HOME PHONE -->
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.homePhone')}`" />
              <v-text-field
                :placeholder="`${$t('ticketUpdate.step2.homePhone')}`"
                v-model="userData.contact.homePhone"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- MOBILE PHONE -->
            <v-col cols="12" sm="6" md="4" class="px-1">
              <label v-html="`${$t('ticketUpdate.step2.mobilePhone')} *`" />
              <v-text-field
                :placeholder="`${$t('ticketUpdate.step2.mobilePhone')} *`"
                v-model="userData.phone"
                required
                :rules="mobilePhoneRules"
                outlined
                dense
                @input="validateForm2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters justify="end" class="px-3">
            <v-btn
              :disabled="disabledUpdate"
              color="primary"
              v-html="$t('ticketUpdate.step2.button')"
              @click="updateData"
            />
          </v-row>
        </div>
      </v-form>
    </div>
    <ResponseMessage
      v-if="responseUpdateData"
      :response="responseUpdateData"
      class="mt-1"
    />

    <!-- ############################################# -->
    <!-- ################ STEP 3 ##################### -->
    <!-- ############################################# -->

    <div class="grey lighten-3 pa-3 rounded mt-6" v-if="step >= 3">
      <h3><span class="step">3</span> {{ $t("ticketUpdate.step3.header") }}</h3>
      <div class="step3-info mt-3" v-if="step == 3">
        <div
          class="font-weight-bold"
          v-if="!isTicketMulti"
          v-html="$t('ticketUpdate.step3.html.buoniPasto')"
        />

        <div v-else>
          <p
            class="text-uppercase"
            v-html="$t('ticketUpdate.step3.html.buoniMultiuse.header')"
          ></p>
          <p
            class="text-uppercase"
            v-html="$t('ticketUpdate.step3.html.buoniMultiuse.text')"
          ></p>
        </div>

        <v-row no-gutters justify="center">
          <v-btn
            v-if="!isTicketMulti"
            depressed
            :x-large="$vuetify.breakpoint.smAndUp"
            class="secondary mt-5"
            @click="showRegolamento"
          >
            Clicca qui per il<br />regolamento completo
          </v-btn>

          <v-btn
            v-else
            depressed
            :x-large="$vuetify.breakpoint.smAndUp"
            class="secondary mt-5"
            @click="showRegolamentoMultiuse"
          >
            Clicca qui per il<br />regolamento completo
          </v-btn>
        </v-row>
        <div class="info-images">
          <div>
            <img src="/img_layout/ticket_1_new.jpg" />
          </div>
          <div>
            <img src="/img_layout/ticket_2.png" />
          </div>
        </div>
        <v-row no-gutters justify="center">
          <v-btn
            v-if="!isTicketMulti"
            depressed
            :x-large="$vuetify.breakpoint.smAndUp"
            class="secondary showDetails"
            @click="showTicketDetails"
          >
            clicca qui per gestire la modalità<br />di utilizzo dei buoni pasto
          </v-btn>

          <v-btn
            v-else
            depressed
            :x-large="$vuetify.breakpoint.smAndUp"
            class="secondary showDetails"
            @click="showTicketDetails"
          >
            clicca qui per gestire la modalità<br />di utilizzo dei buoni
            multiuso
          </v-btn>
        </v-row>
      </div>
    </div>

    <!-- ############################################# -->
    <!-- ################ STEP 4 ##################### -->
    <!-- ############################################# -->

    <div class="grey lighten-3 pa-3 rounded mt-6" v-if="step >= 4">
      <h3 v-if="!isTicketMulti">
        <span class="step">4</span>
        {{ $t("ticketUpdate.step4.header.buoniPasto") }}
      </h3>
      <h3 v-else>
        <span class="step">4</span>
        {{ $t("ticketUpdate.step4.header.buoniMultiuso") }}
      </h3>
      <div class="ticket-status mt-3" v-if="step === 4">
        <div v-if="!isTicketMulti">
          <div v-if="oldData.ticketServiceEnabled">
            <span v-html="$t('ticketUpdate.step4.serviceEnabled')" />
            <div
              class="update-link text-uppercase mt-2"
              @click="updateUserTicketEnabled(false)"
              v-html="$t('ticketUpdate.step4.updateLink')"
            />
          </div>
          <div v-if="!oldData.ticketServiceEnabled">
            <span v-html="$t('ticketUpdate.step4.serviceDisabled')" />

            <div
              class="update-link text-uppercase mt-2"
              @click="updateUserTicketEnabled(true)"
              v-html="$t('ticketUpdate.step4.updateLink')"
            />
          </div>
        </div>
        <div v-else>
          <p v-html="$t('ticketUpdate.step4.enableServiceMultiuso')" />

          <div
            class="update-link text-uppercase mt-2"
            @click="updateUserTicketEnabled(false)"
            v-html="$t('ticketUpdate.step4.updateLink')"
          />
          <span>{{ $t("ticketUpdate.step4.serviceMultiusoActivation") }}</span>
        </div>

        <v-row no-gutters justify="center">
          <v-btn
            depressed
            x-large
            class="secondary mt-5 primary--text"
            @click="goToHome"
            v-html="$t('ticketUpdate.homeBtn')"
          />
        </v-row>
      </div>
    </div>

    <!-- ############################################# -->
    <!-- ################ STEP 5 ##################### -->
    <!-- ############################################# -->

    <div
      class="grey lighten-3 pa-3 rounded mt-6"
      v-if="step >= 5 && !isTicketMulti"
    >
      <h3>
        <span class="step">5</span>
        {{ $t("ticketUpdate.step5.header.buoniPasto") }}
      </h3>
      <div class="ticket-status mt-3">
        <span v-html="$t('ticketUpdate.step5.thanks')" />
        <br />
        <div v-if="finalStatus.ticketServiceEnabled">
          <span v-html="$t('ticketUpdate.step5.serviceEnabled')" />
        </div>
        <div v-if="!finalStatus.ticketServiceEnabled">
          <span v-html="$t('ticketUpdate.step5.serviceDisabled')" />
        </div>
        <br />
        <span v-html="$t('ticketUpdate.step5.editMessage')" />
        <v-row no-gutters justify="center">
          <v-btn
            depressed
            x-large
            class="secondary mt-5 primary--text"
            @click="goToHome"
            v-html="$t('ticketUpdate.homeBtn')"
          />
        </v-row>
      </div>
    </div>
    <div
      class="grey lighten-3 pa-3 rounded mt-6"
      v-if="step >= 5 && isTicketMulti"
    >
      <h3>
        <span class="step">5</span>
        {{ $t("ticketUpdate.step5.header.buoniMultiuso") }}
      </h3>
      <div class="ticket-status mt-3">
        <span v-html="$t('ticketUpdate.step5.thanks')" />
        <br />
        <v-row no-gutters justify="center">
          <v-btn
            depressed
            x-large
            class="secondary mt-5 primary--text"
            @click="goToHome"
            v-html="$t('ticketUpdate.homeBtn')"
          />
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss">
.ticket-update-container {
  .v-autocomplete {
    input,
    ::placeholder {
      font-size: 16px !important;
    }
  }
  div[class*="col-"] {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 12px !important;
    }
  }
  h3 {
    margin-left: 0;
    margin-right: 0;
    font-size: 20px;
    .step {
      background-color: $secondary;
      border-radius: 25px;
      padding: 0 15px;
      display: inline-block;
    }
  }
  .v-input__slot {
    background: white;
  }
  .v-input--radio-group {
    margin-top: 13px;
    .v-input__slot {
      background: transparent;
    }
  }
  .responseOk {
    background: var(--v-success-base);
    color: $white;
  }

  .step3-info {
    margin: 0px 10px 10px 10px;
    background: $primary;
    color: #fff;
    font-size: 25px;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;

    .v-btn {
      height: 50px;
      border: 1px solid $primary;
      border-color: $primary !important;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: 70px;
        .v-btn__content {
          font-size: 17px !important;
        }
      }
      &.showDetails {
        margin-top: 0;
      }
    }

    .info-images {
      margin-top: -20px;
      margin-bottom: -15px;

      > div {
        margin-top: 10px;
        background: #fff;
        border-radius: 15px;
        padding: 10px;

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 30px;
        }
        img {
          max-width: 100%;
        }
      }
    }

    span {
      display: block;

      &.divider {
        margin-top: 20px;
      }

      &.info-highlight {
        color: $secondary;
        font-size: 30px;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 10px;
      font-size: 15px;

      span {
        &.divider {
          margin-top: 10px;
        }
        &.info-highlight {
          font-size: 20px;
        }
      }
    }
  }

  .ticket-status {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    .update-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import UserAddress from "@/components/profile/form/UserAddress.vue";
import categoryMixin from "~/mixins/category";
import recaptcha from "~/mixins/recaptcha";
import reload from "~/mixins/reload";

import RegistrationService from "@/service/tigrosRegistrationService";
import TigrosCustomService from "@/service/tigrosCustomService";
import GeoService from "~/service/geoService";

import isEmpty from "lodash/isEmpty";
import {
  requiredValue,
  isCF,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";
import { isFidelityCard } from "@/tigrosValidationRules";

export default {
  name: "TicketUpdate",
  data() {
    return {
      valid: false,
      disabledUpdate: false,
      menu: false,
      step: 1,
      userData: null,
      oldData: null,
      requiredRules: requiredValue,
      cfRules: [requiredValue(), isCF()],
      fidelityCardRules: [requiredValue(), isFidelityCard()],
      requiredCountry: [requiredValue("Selezionare una nazione")],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ],
      customCountries: global.config.customCountries,
      countryList: [],
      geoParams: null,
      responseFind: {},
      responseUpdateData: {},
      supportData: {
        bornCountry: null,
        country: {
          itemId: null
        }
      },
      form1: {
        fiscalCode: null,
        fidelityCard: null,
        firstName: null,
        lastName: null
      },
      confirmed: null,
      finalStatus: null
    };
  },
  props: {
    ticketType: { type: String, required: true }
  },
  mixins: [reload, categoryMixin, recaptcha],
  components: { CategoryTitle, ResponseMessage, UserAddress },
  computed: {
    isTicketMulti() {
      return this.ticketType == "multiuso";
    },
    disableStep1() {
      return !isEmpty(this.userData);
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    findUser() {
      this.responseFind = {};
      let _this = this;
      RegistrationService.findUserForTicket(
        _this.form1.fidelityCard,
        _this.form1.fiscalCode,
        _this.form1.firstName,
        _this.form1.lastName
      ).then(function(data) {
        if (data.response.status == 0) {
          _this.userData = data.data.user;
          _this.oldData = data.data;

          _this.step = 2;
          _this.$nextTick(() => {
            let country = global.config.customCountries.find(c => {
              return c.itemId === _this.userData.billingAddress.countryId;
            });
            _this.supportData.country = country;
            if (_this.$refs.addressDataForm) {
              _this.$refs.addressDataForm.changeCountry(country, false);
            }
          });
          if (_this.confirmed) {
            _this.step = 3;
          }

          _this.selectBornCountry(_this.customCountries[0]);
          TigrosCustomService.getCountries(-1).then(function(countryList) {
            _this.countryList = countryList;
            for (var i = 0; i < _this.countryList.length; i++) {
              if (
                _this.userData.billingAddress.countryId ==
                _this.countryList[i].itemId
              ) {
                _this.changeCountry(_this.countryList[i]);
              }
            }
          });
        }
        _this.responseFind = data.response;
      });
    },

    selectBornCountry(item) {
      this.supportData.bornCountry = item;
      var found = false;
      for (var i = 0; i < this.userData.person.personInfos.length; i++) {
        if (this.userData.person.personInfos[i].personInfoTypeId == 14) {
          this.userData.person.personInfos[i].value = item.itemId;
          found = true;
          break;
        }
      }
      if (!found) {
        this.userData.person.personInfos.push({
          personInfoTypeId: "14",
          value: item.itemId
        });
      }
    },
    changeCountry(country, resetAll) {
      if (resetAll) {
        this.userData.billingAddress = {
          addressId: this.userData.billingAddress.addressId
        };
      }
      this.userData.billingAddress.countryId = country.itemId;
      //TODO: verificare la composizione del filtro geoParams da passare al GeoService
      // if (country.itemId == 1) {
      //   this.geoParams = null;
      // } else {
      //   this.geoParams = {
      //     components: "country:" + country.iso
      //   };
      // }
    },
    validateForm2(isAddressValid) {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form2) {
          let isForm2Valid = _this.$refs.form2.validate();
          _this.disabledUpdate = !isForm2Valid || !isAddressValid;
        }
      }, 200);
    },
    updateData() {
      this.disabledUpdate = true;
      this.responseUpdateData = null;
      let _this = this;
      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }
      GeoService.lookup(this.userData.billingAddress, this.geoParams).then(
        function(results) {
          if (results.length > 0) {
            RegistrationService.updateUserDataForTicket(_this.userData).then(
              function(data) {
                _this.responseUpdateData = data.response;
                _this.confirmed = true;
                _this.step = 3;
                _this.disabledUpdate = false;
              },
              function() {
                _this.confirmed = false;
                _this.disabledUpdate = false;
              }
            );
          } else {
            _this.responseUpdateData = {
              errors: [{ error: "L'indirizzo inserito non è stato trovato" }]
            };
            // $location.search("confirmed", false);
            _this.confirmed = false;
            _this.disabledUpdate = false;
          }
        }
      );
    },
    showRegolamento() {
      window.open("/p/regolamento-buoni-pasto", "_blank");
    },
    //TODO: Add the link after the content of the page is provided by Tigros.
    showRegolamentoMultiuse() {
      window.open("/page/regolamento-buoni-multiuso", "_blank");
    },
    showTicketDetails() {
      this.step = 4;
      this.responseUpdateData = null;
    },
    goToHome() {
      this.$router.push({ name: "Home" });
    },
    updateUserTicketEnabled(enabled) {
      let _this = this;
      RegistrationService.updateUserTicketEnabled(
        _this.form1.fidelityCard,
        _this.form1.fiscalCode,
        _this.form1.firstName,
        _this.form1.lastName,
        enabled
      ).then(
        function(data) {
          if (data.response.status == 0) {
            _this.finalStatus = data.data;
            _this.step = 5;
          }
        },
        function() {}
      );
    }
  },
  mounted() {
    this.supportData.bornCountry = this.customCountries[0];
    this.supportData.country = this.customCountries[0];
    if (
      this.form1.fiscalCode &&
      this.form1.fidelityCard &&
      this.form1.firstName &&
      this.form1.lastName
    ) {
      this.findUser();
    }
  },
  created() {
    this.form1.fidelityCard = this.$route.query["fidelity-card"] || null;
    this.form1.fiscalCode = this.$route.query["fiscal-code"] || null;
    this.form1.firstName = this.$route.query["first-name"] || null;
    this.form1.lastName = this.$route.query["last-name"] || null;
    this.confirmed =
      (this.$route.query["confirmed"] && this.$route.query["confirmed"]) ===
      "true";

    // TEST
    // this.form1.fidelityCard = "0410700827973";
    // this.form1.fiscalCode = "TSTTTR22M10Z230B";
    // this.form1.firstName = "testRsss";
    // this.form1.lastName = "testCsss";
    // this.confirmed = true;
  }
};
</script>
